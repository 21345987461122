import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { Layout, Icon, Row, Col } from 'antd';
import './../App.css';
import panel from '../assets/ImagesBigEngLish/79503361_1049013315429981_1654108578476195840_n.png'
import UnitScreen from './UnitScreen';
import LessonScreen from './LessonScreen';
import ActivitiesScreen from './ActivitiesScreen';
import QuestionScreen from './QuestionScreen';
const { Content } = Layout;


class UnitBigEngLish extends React.Component {
    render() {
        return (

            <Layout style={{ display: 'flex', flex: 1, height: '100vh', backgroundColor: '#f0f2f5' }}>
                <Content style={{ flex: 1, justifyContent: 'center' }}>
                    <Row type='flex'>
                        <Col md={{ span: 24 }}
                            xs={{ span: 24 }}>
                            <div style={{ backgroundColor: 'white', padding: 24, flex: 1, overflowY: '', }}>
                                <div style={{ backgroundColor: 'white', padding: 24, flex: 1, overflowY: '', }}>
                                    <div style={{ position: 'relative', color: 'white', textAlign: 'center' }}>
                                        <img src={panel} style={{ width: '100%', height: 200 }} />
                                        <div style={{
                                            position: 'absolute',
                                            top: '50%', left: '50%',
                                            transform: `translate(${-50}%, ${-50}%)`,
                                            textAlight: 'center',
                                            fontSize: '40px'
                                        }}>
                                            Big English L3 (AmE)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row type='flex' style={{ backgroundColor: '#f0f2f5' }}>
                        <Col md={{ pull: 4, push: 4, span: 16 }} lg={{ pull: 4, push: 4, span: 16 }}
                            xs={{ pull: 4, push: 4, span: 16 }} sm={{ pull: 4, push: 4, span: 16 }}>
                            <div style={{ backgroundColor: '#f0f2f5', flex: 1, overflowY: '', }}>
                                <div style={{ backgroundColor: '#f0f2f5', padding: 24, flex: 1, overflowY: '', }}>
                                    <Router>
                                        <Route exact path='/' component={UnitScreen} />
                                        <Route exact path='/:id/Lesson' component={LessonScreen} />
                                        {/* `Unit/${unit.id}/Lesson` */}
                                        <Route exact path='/:id/Lesson/:id/Activities' component={ActivitiesScreen} />
                                        <Route exact path='/:id/Lesson/:id/Activities/:id/question' component={QuestionScreen} />
                                    </Router>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        )
    }
}
export default UnitBigEngLish;