import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as actions from '../actions/index';
import QuestionContent from './QuestionContent';
import { Link } from 'react-router-dom';
import { Icon, Row } from 'antd'
import renderHTML from 'react-render-html';

class QuestionScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			unitId: this.props.unitId,
			lessonId: this.props.lessonId,
			activityId: this.props.activityId
		}
	}
	BackButton = () => {
		var lessonId = parseInt(localStorage.getItem('lessonIdLocalStorage'));
		var unitId = parseInt(localStorage.getItem('unitIdLocalStorage'))
		return (
			<React.Fragment>
				<Link to={`/${unitId}/Lesson/${lessonId}/Activities`}
					onClick={() => this.onbackToActivity(lessonId, unitId)}
				>
					<div
					>
						<span style={{
							whiteSpace: 'nowrap',
							fontSize: 18,
							fontWeight: '400',
							color: 'black',
							marginRight: 15,
						}}>Exit</span>
						<Icon type="export" style={{ color: 'black' }}></Icon>
					</div>
				</Link>
			</React.Fragment >
		);
	};

	onbackToActivity = (lessonId, unitId) => {
		this.props.onGetActivitiesInQuestion(lessonId, unitId)
	}
	getQuestionType = (questionsType) => {
		this.props.onRenderQuestionType(questionsType);
	}
	render() {
		var { questions } = this.props;
		var questionsIndex = questions[0]
		return (
			<React.Fragment style={{ backgroundColor: 'B3E3FA', padding: 10 }}>
				<div style={{ textAlign: 'right', paddingBottom: 15 }}>
					{this.BackButton()}
				</div>
				<div style={{
					height: '75vh',
					background: 'white',
					marginTop: 20,
					border: '10px solid #D1DB26',
					borderRadius: '8px',
					overflowY: 'auto',
					position: 'relative', padding: '3.2%',
				}}>
					<Row style={{ top: 0, }} >
						<h2 style={{ textAlign: 'left' }}>
							{renderHTML(questionsIndex.Questions[0].QuestionText)}
						</h2>
						<QuestionContent QuestionsIndex={questionsIndex} />
					</Row>
				</div>
			</React.Fragment>
		)
	};
}

const mapStateToProps = (state) => {
	return {
		questions: state.QuestionsReducer.questionInActivityId,
		activityId: parseInt(localStorage.getItem('activityIdLocalStorage')),
		lessonId: parseInt(localStorage.getItem('lessonIdIdLocalStorage')),
		unitId: parseInt(localStorage.getItem('unitIdLocalStorage'))
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		onSaveQuestionScreen: (id) => {
			dispatch(actions.getQuestionsInActivity(id))
		},
		onGetQuestionScreen: (id) => {
			dispatch(actions.getQuestionsInActivity(id))
		},
		onGetActivitiesInQuestion: (lessonId, unitId) => {
			dispatch(actions.getActivitiesInLesson(lessonId, unitId))
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(QuestionScreen);