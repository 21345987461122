import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Row, Col, Popover } from "antd";
import { Icon } from "./Icon";
import ReactPlayer from 'react-player';
import renderHTML from 'react-render-html';



class Listen extends React.Component {

    render() {
        console.log(this.props.audioURL)
        var { audioURL, imageURL, AnswerText } = this.props;
        console.log(imageURL);
        return (

            <React.Fragment>
              
                <div style={{ paddingTop: '10%' }}>

                    <img style={{ width: '100%' }} src={require(`../assets/ImagesBigEngLish/${imageURL}`)} />
                    <audio controls style={{
                        position: 'absolute',
                        left: '50%',
                        transform: `translate(${-50}%, ${500}%)`,

                    }} >

                        <source src={require(`../assets/AudiosBigEngLish/${audioURL}`)} type="audio/ogg"></source>
                    </audio>
                </div>

            </React.Fragment>
        )
    }
}
export default Listen;
