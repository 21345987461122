import React from 'react';
import { Col, Row, Card, Button, Icon } from 'antd';
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/index';
const { Meta } = Card;

class LessonScreen extends React.Component {
	onSaveLesson = (id, unitId) => {
        console.log("TCL: LessonScreen -> onSaveLesson -> unitId", unitId)
        console.log("TCL: LessonScreen -> onSaveLesson -> id", id)
		this.props.onSaveLessonId(id, unitId);
	}
	render() {
		var { listLessonFormUnit, unitId } = this.props.lessons;
		const PageLessonButton = (props) => (
			<div style={{ margin: 4 }}>
				<Link to={props.to}>
					<div
						style={{
							paddingLeft: 12,
							paddingRight: 12,
							display: 'flex',
							height: 44,
							width: 'auto',
							backgroundColor: '#2980b9',
							borderRadius: 22,
							justifyContent: 'center',
							alignItems: 'center',
							cursor: 'pointer',
						}}>
						<span style={{ whiteSpace: 'nowrap', color: 'white', fontSize: 18, fontWeight: '700' }}>Activity</span>
					</div>
				</Link>
			</div>
		);
		const PreviousPageButton =
			((props) => {
				return (
					<React.Fragment>
						<Link to='/'>
							<div>
								<Button
									style={{
										paddingLeft: 12,
										paddingRight: 12,
										display: 'flex',
										height: 44,
										width: 'auto',
										backgroundColor: '#2980b9',
										borderRadius: 22,
										justifyContent: 'center',
										alignItems: 'center',
										cursor: 'pointer',
									}}>
									<span style={{ whiteSpace: 'nowrap', color: 'white', fontSize: 18, fontWeight: '700' }}>Back</span>
								</Button>
							</div>
						</Link>
					</React.Fragment>
				);
			});
		const BackButton =
			((props) => {
				return (
					<React.Fragment>
						<Link to='/'>
							<div>
								<Icon type='arrow-left' style={{ color: 'black' }}></Icon>
								<span style={{
									whiteSpace: 'nowrap',
									fontSize: 18,
									fontWeight: '400',
									color: 'black',
									marginLeft: 15
								}}>Back to Units</span>
							</div>
						</Link>
					</React.Fragment>
				);
			});
		return (
			<React.Fragment>
				<div style={{ textAlign: 'left', paddingBottom: 15 }}>
					<BackButton />
				</div>
				<Row>
					<Row gutter={16}>
						{listLessonFormUnit.length > 0 && listLessonFormUnit.map((lesson, index) => {
							console.log("TCL: LessonScreen -> render -> lesson.Id", lesson.Id)

							return (
								<Col key={index} md={{ span: 8 }} lg={{ span: 6 }} xs={{ span: 6 }} sm={{ span: 12 }}
									style={{ position: 'obsolute', paddingTop: 10, }}>
									<Link to={`Lesson/${lesson.Id}/Activities`}>
										<div
											onClick={() => this.onSaveLesson(lesson.Id, unitId)}
										>
											<Card style={{ boxShadow : '0px 1px 0px #888888'}}
												cover={<img alt="example" src={lesson.urlImage} />}
											>
												<p style={{ fontSize: 18, textAlign: 'center', color: 'black' }}><b>{lesson.LessonName}</b></p>
											</Card>
										</div>
									</Link>
								</Col>
							)
						})}
					</Row>
				</Row>
			</React.Fragment>

		)
	};
}
const mapStateToProps = (state) => {
	return {
		lessons: state.LessonReducer,
	}
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		onSaveLessonId: (id, unitId) => {
			dispatch(actions.getActivitiesInLesson(id, unitId))
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(LessonScreen);

