import React from 'react'
import { Button, Col, Icon } from 'antd';
import QuestionAndSing from './QuestionAndSing';
import renderHTML from 'react-render-html';



class NextImageAndQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        }
        this.refAudio = React.createRef();
    }
    onNext = () => {
        this.setState({
            index: this.state.index + 1
        });
        // this.refAudio.current.pause();
        // this.refAudio.current.load();   

    }
    onBack = () => {
        this.setState({
            index: this.state.index - 1
        })
    }
    render() {
        var { imageAndQuestion } = this.props
        var { index } = this.state;
        const image = imageAndQuestion[index].UrlImage;
        // const audios = AudioAndQuestion[index].UrlAudio;
        // console.log(imageAndQuestion[index].UrlImage)
        console.log(index)
        // console.log(AudioAndQuestion[index])
        return (
            <React.Fragment>
                <div >
                    <div>
                        {/* <QuestionAndSing imageAndQuestion={imageAndQuestion[index]} /> */}
                        {imageAndQuestion[index].Name !== '' &&
                            <h2 style={{ textAlign: 'center' }}>
                                {renderHTML(imageAndQuestion[index].Name)}
                            </h2>
                        }
                        {imageAndQuestion[index].UrlImage !== '' &&
                            <Col key={index} span={24} style={{textAlign:'center'}}>
                                <img alt="example" style={{ fontSize: '30%', maxHeight: '90%' , maxWidth : ''   }} src={require(`../../assets/ImagesBigEngLish/${image}`)} />
                            </Col>
                        }
                        <div style={{textAlign: 'center', paddingTop: 450 }}>
                            {index === 0 ? '' : <Icon type="left-circle" style={{ right: 0, marginRight: 20 , height :42, color: '#107a94', fontSize: 40 }} onClick={this.onBack} />}
                            {index < (imageAndQuestion.length - 1)
                                ? <Icon type="right-circle"  style={{ right: 0, height :42, color: '#107a94',fontSize: 40}} onClick={this.onNext}  />
                                :
                                ''
                            }
                        </div>
                        {/* {image &&  <img scr={image}}
                        {text && <} */}

                    </div>
                    {/* <audio controls
                        ref={this.refAudio} 
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: `translate(${-50}%, ${200}%)`,
                        }}
                    >
                        <source src={require(`../../assets/AudiosBigEngLish/${audios}`)} type="audio/ogg"></source>
                    </audio> */}
                </div>
            </React.Fragment>
        )
    }
}

export default NextImageAndQuestion;