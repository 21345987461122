import React from "react";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "./../fontawesome/pro-solid-svg-icons";

import "./styles.css";
library.add(fas, fab);

/**
|--------------------------------------------------
| Icon component
|--------------------------------------------------
*/
export const HomeIcon = () => (
  <FontAwesomeIcon className="anticon" icon="home" />
);

export const HomeIconPro = () => (
  <FontAwesomeIcon className="anticon" icon={faHome} />
);

/**
|--------------------------------------------------
| Icon question type 
|--------------------------------------------------
*/
export const Icon = props => {
  let { icon, size, color } = props;
  return (
    <FontAwesomeIcon
      icon={icon}
      size={size ? size : "1x"}
      color={color ? color : "#09386d"}
    />
  );
};
