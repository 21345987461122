export const Units =
    [
        {
            "id": 1,
            "UnitName": "Unit 3",
            "urlImage": "Annotation2019-12-17160300.png"
        }
        ,
        {
            "id":2,
            "UnitName": "Unit 2",
            "urlImage": "Annotation2019-12-17160301.PNG"
        }
    ]