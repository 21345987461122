import * as Types from '../constants/actionTypes';
import * as Data from './../DataBigEnglish/questions'
var initialState = Data.questions;

var questionsReducer = (state = initialState, action) => {
    var questionInActivityId  = null;
    var activityIdInLocalStorage = parseInt(localStorage.getItem('activityIdLocalStorage'));
    var lessonIdInLocalStorage = parseInt(localStorage.getItem('lessonIdLocalStorage'));
    var unitIdInLocalStorage = parseInt(localStorage.getItem('unitIdLocalStorage'));
    switch (action.type) {
        case Types.GETQUESTIONINACTIVITIES:
            questionInActivityId = initialState.filter(x => x.Id === action.activityId && x.unitId === action.unitId && x.lessonId === action.lessonId);
            return {
                questionInActivityId,
            };
        case Types.GETACTIVITIESINLESSON:
            const listActivitiesFromLesson = initialState.find(x => x.lessonId === action.id && x.unitId === action.unitId);
            return {
                listActivitiesFromLesson
            }
        case Types.GETLESSONFROMUNIT:
            const listLessonFormUnit = initialState.filter(x => x.UnitId === action.id);
            var unitId = action.id
            return {
                listLessonFormUnit,
                unitId
            }
        default:
            questionInActivityId = initialState.filter(x => x.Id === activityIdInLocalStorage && x.unitId ===unitIdInLocalStorage && x.lessonId === lessonIdInLocalStorage);
            return {
                questionInActivityId,
            };
    }
}
export default questionsReducer;