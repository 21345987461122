import React from 'react';
import { Button } from 'antd';
import renderHTML from 'react-render-html';

class QuestionAndSing extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var { AudioAndQuestionIndex } = this.props
        return (
            <React.Fragment>
                <div>
                    {AudioAndQuestionIndex.Name &&
                        <div>
                            <h2 style={{ textAlign: 'center' }}>
                                {renderHTML(AudioAndQuestionIndex.Name)}
                            </h2>
                        </div>}
                    {/* {AudioAndQuestionIndex.Name} */}
                </div>
                <div>
                    {AudioAndQuestionIndex.UrlImage &&
                        <div>
                            <img alt="example" style={{ width: '75%' }} src={require(`./../../assets/ImagesBigEngLish/${AudioAndQuestionIndex.UrlImage}`)} />
                        </div>}
                        
                    {/* {AudioAndQuestionIndex.Name} */}
                </div>
                
            </React.Fragment>
        )
    }
}
export default QuestionAndSing;