import React from 'react';
import QuestionAndSing from './QuestionAndSing';
import { Button, Icon, Col } from 'antd';

class ListenMutiple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            play_1: false,
            play_2: false,
        }
        this.refAudio = React.createRef();
    }
    onNext = () => {
        this.setState({
            index: this.state.index + 1
        });
        this.green.pause();
        this.green.load();
        // this.refAudio.current.pause();
        // this.refAudio.current.load();

    }
    onBack = () => {
        this.setState({
            index: this.state.index - 1
        })
    }
    togglePlay = (toggle) => {
        console.log(toggle)
        if (toggle === "audio_1") {
            this.setState(
                { play_1: !this.state.play_1 }
                , () => {
                    this.state.play_1 ? this.audio_1.play() : this.audio_1.pause();
                });
            setTimeout(() => {
                this.setState({
                    play_1: false
                });
            }, 3000);
        }
        if (toggle === "audio_2") {
            this.setState(
                { play_2: !this.state.play_2 }
                , () => {
                    this.state.play_2 ? this.audio_2.play() : this.audio_2.pause();
                });
            setTimeout(() => {
                this.setState({
                    play_2: false
                });
            }, 3000);
        }
    }
    render() {
        var { AudioAndQuestion } = this.props
        var { index } = this.state;
        const audios = AudioAndQuestion[index].UrlAudio;
        console.log(AudioAndQuestion[index].UrlAudio)
        console.log(index)
        console.log(AudioAndQuestion[index])
        return (
            <React.Fragment>
                <div >
                    <div style={{ textAlign: 'center' }}>
                        <QuestionAndSing AudioAndQuestionIndex={AudioAndQuestion[index]} />

                        {index === 0 ? '' : <Icon type="left-circle" style={{ right: 0, marginRight: 10, color: '#107a94', fontSize: 40 }} onClick={this.onBack} />}
                        {index < (AudioAndQuestion.length - 1)
                            ? <Icon type="right-circle" style={{ right: 0, color: '#107a94', fontSize: 40 }} onClick={this.onNext} />
                            :
                            ''
                        }
                        {/* {image &&  <img scr={image}}
                        {text && <} */}
                    </div>
                    {AudioAndQuestion[index].UrlAudio.length == 1 ?
                        <audio controls
                            // ref={this.refAudio}
                            ref={(green) => { this.green = green; }}
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: `translate(${-50}%, ${200}%)`,
                            }}
                        >
                            <source src={require(`./../../assets/AudiosBigEngLish/${audios[0].Audio}`)} type="audio/ogg"></source>
                        </audio>
                        :
                        <Col md={{ span: 6 }} lg={{ span: 6 }}
                            xs={{ span: 6 }} sm={{ span: 6 }} >
                            <div style=
                                {{
                                    position: "absolute",
                                    top: '3%',
                                    left: '-23px',
                                    // width: '50px',
                                    // height: '50px'
                                }}>
                                <Button style={{
                                    border: '1px solid',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'inline-block',
                                    lineHeight: '31px',
                                    paddingLeft: '13px',
                                    // float: 'left'
                                }}
                                    type="primary"
                                    shape="round"
                                    icon={this.state.play_1 === true ? 'pause' : 'caret-right'}
                                    onClick={() => this.togglePlay('audio_1')} />
                                <div className="stage">
                                    <audio ref={(audio_1) => { this.audio_1 = audio_1; }} >
                                        <source src={require(`./../../assets/AudiosBigEngLish/${audios[0].Audio}`)} type="audio/mpeg" >
                                        </source>
                                    </audio>

                                </div>
                                <Button style={{
                                    border: '1px solid',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    display: 'inline-block',
                                    lineHeight: '31px',
                                    paddingLeft: '13px',
                                    // float: 'left'
                                }}
                                    type="primary"
                                    shape="round"
                                    icon={this.state.play_2 === true ? 'pause' : 'caret-right'}
                                    onClick={() => this.togglePlay('audio_2')} />
                                <div className="stage">
                                    <audio ref={(audio_2) => { this.audio_2 = audio_2; }} >
                                        <source src={require(`./../../assets/AudiosBigEngLish/${audios[1].Audio}`)} type="audio/mpeg" >
                                        </source>
                                    </audio>
                                </div>
                            </div>
                        </Col>
                    }
                </div>
            </React.Fragment>
        )
    }
}
export default ListenMutiple;