import React from 'react';
import { Card } from 'antd';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

class ClickImageAndShowtext extends React.Component {
    onChangeCardFilpped = () => {
        this.setState({
            isCardFlipped: !this.state.isCardFlipped
        });
    }
    render() {
        var { ImageAndAudio } = this.props;
        console.log("TCL: ClickImageAndShowtext -> render -> ImageAndAudio", ImageAndAudio)
        console.log(this.props.ImageAndAudio)
        return (
            // <CSSTransition in={isCardFlipped} timeout={200} classNames="my-node">
            //     <Card style={{ width: 300, margin: 5 }}
            //         onClick={this.onChangeCardFilpped}
            //     >
            //         {
            //             isCardFlipped === false ?
            //                 
            //                 :
            //                 
            //         }
            //     </Card>
            // </CSSTransition>
            <div style={{width: '100%'}}>
                <Flippy
                    flipOnHover={false} // default false
                    flipOnClick={true} // default false
                    flipDirection="horizontal" // horizontal or vertical
                    ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
                    // if you pass isFlipped prop component will be controlled component.
                    // and other props, which will go to div
                    style={{ width: '80%', height: '100%' }} /// these are optional style, it is not necessary
                >
                    <FrontSide
                        style={{
                            backgroundColor: '#CEE07C',
                            border: '2px solid lightgreen',
                            borderRadius: 15,
                            marginTop: 20,
                            marginLeft: 20,
                            marginBottom : 10
                           
                        }}
                    >
                        <img
                            alt="example"
                            style={{
                                cursor: 'pointer',
                                width: '80%',
                                margin: 17,
                                // border: '10px solid lightgreen',
                                // borderRadius: '30px'
                            }}
                            src={require(`./../../assets/ImagesBigEngLish/${ImageAndAudio.ImageAudio[0].UrlImage}`)} />
                    </FrontSide>
                    <BackSide
                        style={{
                            backgroundColor: '#BDE4E8',
                            border: '2px solid #98CBD9',
                            borderRadius: 15,
                            marginLeft: 20,
                            // textAlight: 'center'
                        }}>
                        <h2
                            style={{
                                cursor: 'pointer',
                                width: '80%',
                                // margin: 20,
                                // height: 150,
                                position: 'absolute',
                                top: '50%', left: '50%',
                                transform: `translate(${-50}%, ${-50}%)`,
                                textAlign: "center",
                                fontSize: '20px',
                                
                            }}
                        >
                            {ImageAndAudio.ImageAudio[0].Name}
                        </h2>
                    </BackSide>
                </Flippy>
            </div>
        )


    }
}
export default ClickImageAndShowtext;