import * as Types from '../constants/actionTypes';
import * as Data from './../DataBigEnglish/Unit';
var initialState = Data.Units

var unitReducer = (state = initialState, action) => {
    console.log("TCL: unitReducer -> action.type", action.type)
    switch (action.type) {
        default:
            return [...state]
    }
}
export default unitReducer;