import React from 'react';
import * as actions from '../actions/index';
import { connect } from 'react-redux';
import { Row, Col, Card, Button } from 'antd';
import * as QuestionTypes from '../constants/QuestionType';
import 'react-h5-audio-player/lib/styles.css';
import ListenLookAndSay from './typesQuestions/ListenLookAndSay';
import ListenMutiple from './typesQuestions/ListenMutiple';
import Listen from './Listen';
import NextImageAndQuestion from './typesQuestions/NextImageAndQuestion'
import ClickImageAndShowtext from './typesQuestions/ClickImageAndShowtext'
import DragDrop from './typesQuestions/DragDrop';

class QuestionContent extends React.Component {
    render() {
        var { QuestionsIndex } = this.props;
        var { Questions } = QuestionsIndex;
        console.log("TCL: QuestionContent -> render -> Questions", Questions)
        console.log("TCL: QuestionContent -> render -> QuestionsIndex.QuestionType)", QuestionsIndex.QuestionType)
        var question = Questions[0].QuestionText.split('?');

        switch (QuestionsIndex.QuestionType) {
            case QuestionTypes.SHOWIMAGEANDQUESTION:

                console.log(Questions[0].QuestionAnswer[0].TextAnswer)
                return (
                    <div style={{ textAlign: 'center' }}>
                        {Questions[0].QuestionAnswer[0].TextAnswer === null ? "" :
                            <p style={{ fontSize: 22 }}>
                                {Questions[0].QuestionAnswer[0].TextAnswer}
                            </p>
                        }
                        {Questions[0].ImageAudio.map((image, index) => {
                            return (
                                <Col key={index} span={6}>
                                    <img alt="example" style={{ fontSize: 'auto' , maxWidth: '90%', maxHeight: '90%' }} src={require(`../assets/ImagesBigEngLish/${image.UrlImage}`)} />
                                </Col>
                            )
                        })
                        }
                    </div>
                )
            case QuestionTypes.SHOW1IMAGEANDQUESTION:
                return (
                    <div>
                        {Questions[0].ImageAudio.map((image, index) => {
                            return (
                                <Col key={index} span={24} style={{textAlign: 'center'}}>
                                    <p style={{ fontSize: 22  }}>
                                        {Questions[0].QuestionAnswer[0].TextAnswer}
                                    </p>
                                    <img alt="example" style={{ width: '90%' }} src={require(`../assets/ImagesBigEngLish/${image.UrlImage}`)} />
                                </Col>
                            )
                        })
                        }
                    </div>
                )
            case QuestionTypes.LISTENANDSINGONEAUDIO:
                return (
                    <Listen audioURL={Questions[0].ImageAudio[0].UrlAudio} imageURL={Questions[0].ImageAudio[0].UrlImage} />
                )
            case QuestionTypes.LISTENLOOKANDSAY:
                return (
                    <div>
                        {Questions.map((ImageAndAudio, index) => {
                            return (
                                <ListenLookAndSay key={index} ImageAndAudio={ImageAndAudio} />
                            )
                        })
                        }
                    </div>
                )
            case QuestionTypes.CLICKIMAGEANDSHOWTEXT:
                return (
                    <div>
                        {Questions.map((ImageAndAudio, index) => {
                            return (
                                <Col key={index} span={6}>
                                    <ClickImageAndShowtext ImageAndAudio={ImageAndAudio} />
                                </Col>
                            )
                        })
                        }
                    </div>
                )
            case QuestionTypes.NEXTQUESTIONANDSINGFOLOWAUDIO:
                return (
                    <ListenMutiple AudioAndQuestion={Questions[0].ImageAudio} />
                )
            case QuestionTypes.NEXTQUESTIONANDIMAGE:
                return (
                    <NextImageAndQuestion imageAndQuestion={Questions[0].ImageAudio} />
            )
            case QuestionTypes.DRAGDROP:
                return (
                    <DragDrop/>
                )
            default:
                return (
                    <div>
                        <h1>
                            Data is completed
                        </h1>
                    </div>
                )
        }
    }
}
export default QuestionContent;
