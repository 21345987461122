import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Modal, Icon } from 'antd';
import * as actions from '../actions/index';


class ActivitiesScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lessonId: this.props.lessonId,
            unitId: this.props.unitId,
            visible: false
        }
    }
    componentDidMount() {
    }
    showModal = () => {
        console.log('show modal')
        this.setState({
            visible: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
        });
    };
    BackButton = () => {
        var { lessonId, unitId } = this.state;
        return (
            <React.Fragment>
                <Link to={`/${unitId}/lesson`}
                    onClick={() => this.callBackLesson(unitId)}
                >
                    <div
                    >
                        <Icon type='arrow-left' style={{ color: 'black' }}></Icon>
                        <span style={{
                            whiteSpace: 'nowrap',
                            fontSize: 18,
                            fontWeight: '400',
                            color: 'black',
                            marginLeft: 15
                        }}>Back to Lessons</span>
                    </div>
                </Link>
            </React.Fragment>
        );
    };
    callBackLesson = (unitId) => {
        this.props.onGetLessonScreen(unitId);
    }
    onSaveActivityId = (activityId, lessonId, unitId) => {
        this.props.onGetQuestionScreen(activityId, lessonId, unitId);
    }
    componentDidMount() {
    }
    render() {
        var { Activities } = this.props.activities;
        var { lessonId, unitId } = this.state;
        const PageLessonButton = (props) => (
            <div style={{ margin: 4 }}>
                <Link to={props.to}>
                    <div
                        style={{
                            paddingLeft: 12,
                            paddingRight: 12,
                            display: 'flex',
                            height: 44,
                            width: 'auto',
                            backgroundColor: '#2980b9',
                            borderRadius: 22,
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}>
                        <span style={{ whiteSpace: 'nowrap', color: 'white', fontSize: 18, fontWeight: '700' }}>Activity</span>
                    </div>
                </Link>
            </div>
        );


        return (
            <React.Fragment>
                <div style={{ textAlign: 'left', paddingBottom: 15 }}>
                    {this.BackButton()}
                </div>
                <div>

                    {Activities.length > 0 && Activities.map((activity, index) => {
                        return (
                            <Card key={index} style={{ marginBottom: 17, position: 'relative' }}>
                                <div >
                                    <div style={{
                                        border: '1px solid',
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '40px',
                                        display: 'inline-block',
                                        textAlign: 'center',
                                        lineHeight: '40px',
                                        background: '#055A71',
                                        color: 'white',
                                        float: 'left'
                                    }}>
                                        <b style={{ fontSize: 18 }}>{index + 1}</b>
                                    </div>
                                    <div style={{ color: '#055A71', float: 'left', marginTop: 10, marginLeft: 20, fontSize: 18 }} >
                                        <span>{activity.Name}</span>
                                    </div>
                                </div>

                                <Link to={`Activities/${activity.Id}/question`} style={{ position: 'absolute', right: 130, }}>
                                    <Button style={{ backgroundColor: '#F6AB1B' }} type="success" onClick={() => this.onSaveActivityId(activity.Id, lessonId, unitId)}>
                                        <Icon type='desktop' />
                                        Preview
                                    </Button>
                                </Link>
                                <Button style={{ backgroundColor: '#F6AB1B', position: 'absolute', right: 26 }} type="success"
                                    onClick={this.showModal}
                                >
                                    <Icon type='snippets' />
                                    Notices
                                </Button>
                                <Modal
                                    title="Basic Modal"
                                    visible={this.state.visible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                >
                                    <p>Some contents...</p>
                                    <p>Some contents...</p>
                                    <p>Some contents...</p>
                                </Modal>
                            </Card>
                        )
                    })
                    }
                </div>
            </React.Fragment>
        )
    };
}
const mapStateToProps = (state) => {
    return {
        activities: state.ActivitiesReducer.listActivitiesFromLesson,
        lessonId: parseInt(localStorage.getItem('lessonIdLocalStorage')),
        unitId: parseInt(localStorage.getItem('unitIdLocalStorage'))
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onGetQuestionScreen: (activityId, lessonId, unitId) => {
            dispatch(actions.getQuestionsInActivity(activityId, lessonId, unitId))
        },
        onGetLessonScreen: (unitId) => {
            dispatch(actions.SaveUnitId(unitId))
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesScreen);