import * as Types from './../constants/actionTypes';
var initialState =
    [
        {
            "unitId": 1,
            "lessonId": 1,
            "Activities": [
                {
                    "Id": 1,
                    "Name": "Warm-up\r\n"
                },
                {
                    "Id": 2,
                    "Name": "Lesson Objective\r\n"
                },
                {
                    "Id": 3,
                    "Name": "Song Time\r\n"
                },
                {
                    "Id": 4,
                    "Name": "Presentation\r\n"
                },
                {
                    "Id": 5,
                    "Name": "Practice\r \n1"
                },
                {
                    "Id": 6,
                    "Name": "Game\r\n"
                },
                {
                    "Id": 7,
                    "Name": "Listen and sing\r\n"
                },
                {
                    "Id": 8,
                    "Name": "Speaking\r\n"
                },
                {
                    "Id": 9,
                    "Name": "Practice\r\n 2"
                },
                {
                    "Id": 10,
                    "Name": "Think Big\r\n"
                },
                {
                    "Id": 11,
                    "Name": "Lesson Objective \r\n"
                },
                {
                    "Id": 12,
                    "Name": "Homework\r\n"
                }
            ],
        },
        {
            "unitId": 1,
            "lessonId": 2,
            "Activities": [
                {
                    "Id": 1,
                    "Name": "Warm-up\r\n"
                },
                {
                    "Id": 2,
                    "Name": "Lesson Objective\r\n 1"
                },
                {
                    "Id": 15,
                    "Name": "Revision"
                },
                {
                    "Id": 16,
                    "Name": "Pre-reading"
                },
                {
                    "Id": 17,
                    "Name": "Reading"
                },
                {
                    "Id": 14,
                    "Name": "Comprehension 1"
                },
                {
                    "Id": 18,
                    "Name": "Comprehension 2"
                },
                {
                    "Id": 19,
                    "Name": "Comprehension 3"
                },
                {
                    "Id": 10,
                    "Name": "Think Big\r\n"
                },
                {
                    "Id": 12,
                    "Name": "Homework\r\n"
                },
            ]
        }
        ,
        {
            "unitId": 1,
            "lessonId": 3,
            "Activities": [
                {
                    "Id": 1,
                    "Name": "Warm-up\r\n"
                },
                {
                    "Id": 2,
                    "Name": "Lesson Objective\r\n 1"
                },
                {
                    "Id": 5,
                    "Name": "Practice\r \n1"
                },
                {
                    "Id": 9,
                    "Name": "Practice\r\n 2"
                },
                {
                    "Id": 11,
                    "Name": "Lesson Objective 2\r\n"
                },
                {
                    "Id": 14,
                    "Name": "Comprehension 1"
                },
                {
                    "Id": 20,
                    "Name": "Pre-listening"
                },
                {
                    "Id": 21,
                    "Name": "Listening"
                },
                {
                    "Id": 22,
                    "Name": "Role play"
                },
                {
                    "Id": 23,
                    "Name": "Presentation grammar 1"
                },
                {
                    "Id": 24,
                    "Name": "Presentation grammar 2"
                },
                {
                    "Id": 25,
                    "Name": "Practice 3"
                },
                {
                    "Id": 26,
                    "Name": "Practice 4"
                }
            ]
        },
        {
            "unitId": 1,
            "lessonId": 4,
            "Activities": [
                {
                    "Id": 1,
                    "Name": "Warm-up\r\n"
                },
                {
                    "Id": 2,
                    "Name": "Lesson Objective\r\n 1"
                },
                {
                    "Id": 5,
                    "Name": "Practice\r \n1"
                },
                {
                    "Id": 9,
                    "Name": "Practice\r\n 2"
                },
                {
                    "Id": 25,
                    "Name": "Practice 3"
                },
                {
                    "Id": 26,
                    "Name": "Practice 4"
                },
                {
                    "Id": 27,
                    "Name": "Practice 5"
                },
                {
                    "Id": 28,
                    "Name": "Review vocabulary\r\n"
                },
                {
                    "Id": 29,
                    "Name": "Review grammar"
                },
                {
                    "Id": 30,
                    "Name": "Presentation writing"
                },
                {
                    "Id": 31,
                    "Name": "Presentation phonics"
                },
                {
                    "Id": 33,
                    "Name": "Practice 6"
                },
                {
                    "Id": 34,
                    "Name": "Practice 7"
                }
            ]
        }
    ]
    ;
var activitiesReducer = (state = initialState, action) => {
    var listActivitiesFromLesson = null
    var lessonIdInLocalStorage = parseInt(localStorage.getItem('lessonIdLocalStorage'));
    console.log("TCL: activitiesReducer -> lessonIdInLocalStorage", lessonIdInLocalStorage)
    var unitIdInLocalstorage = parseInt(localStorage.getItem('unitIdLocalStorage'));
    console.log("TCL: activitiesReducer -> unitIdInLocalstorage", unitIdInLocalstorage)
    switch (action.type) {
        case Types.GETACTIVITIESINLESSON:
            listActivitiesFromLesson = initialState.find(x => x.lessonId === action.id && x.unitId === action.unitId);
            return {
                listActivitiesFromLesson
            }
        case Types.GETQUESTIONINACTIVITIES:
            return action.activityId
        case Types.GETLESSONFROMUNIT:
            const listLessonFormUnit = initialState.filter(x => x.UnitId === action.id);
            var unitId = action.id
            return {
                listLessonFormUnit,
                unitId
            }
        default:
            listActivitiesFromLesson = initialState.find(x => x.lessonId === lessonIdInLocalStorage && x.unitId === unitIdInLocalstorage);
            return {
                listActivitiesFromLesson,
            }
    }
}
export default activitiesReducer;