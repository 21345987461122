import * as Types from '../constants/actionTypes';
const initialState = {
    savelessonId: null,
    saveActivityId: null,
};
const BigEnglish = (state = initialState, action) => {
    switch (action.type) {
        case Types.GETQUESTIONINACTIVITIES:
            return {
                saveActivityId: action.id
            }
        default:
            return state;
    }
}
export default BigEnglish;