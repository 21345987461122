import { combineReducers } from 'redux';
import BigEnglish from './BigEnglish';
import UnitReducer from './UnitReducer';
import ActivitiesReducer from './ActivitiesReducer'
import LessonReducer from './LessonReducer';
import QuestionsReducer from './QuestionsReducer'
const myReducers = combineReducers({
    UnitReducer,
    BigEnglish,
    QuestionsReducer,
    ActivitiesReducer,
    LessonReducer,
});
export default myReducers;