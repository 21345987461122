import * as Types from '../constants/actionTypes';
export const getUnit = () => {
    return {
        type: Types.GETUNIT
    }
}

export const getActivitiesInLesson = (id, unitId) => {
    localStorage.setItem('unitIdLocalStorage', unitId);
    localStorage.setItem('lessonIdLocalStorage',id)
    return {
        type: Types.GETACTIVITIESINLESSON,
        id,
        unitId
    }
}
export const getQuestionsInActivity = (activityId, lessonId, unitId) => {
    localStorage.setItem('unitIdLocalStorage', unitId);
    localStorage.setItem('lessonIdLocalStorage',lessonId)
    localStorage.setItem('activityIdLocalStorage',activityId)
    return {
        type: Types.GETQUESTIONINACTIVITIES,
        activityId,
        lessonId,
        unitId
    }
}
export const renderQuestionType = (questionType) => {
    return {
        type: Types.RENDERQUESTIONTYPE,
        questionType
    }
}

export const SaveUnitId = (id) => {
    localStorage.setItem('unitIdLocalStorage', id);
    localStorage.setItem('Types', Types.GETLESSONFROMUNIT)
    return {
        type: Types.GETLESSONFROMUNIT,
        id
    }
}
