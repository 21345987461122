import * as Types from '../constants/actionTypes';
import * as Data from './../DataBigEnglish/LessonFromUnit'
var initialState = Data.LessonInUnit;

var lessonReducer = (state = initialState, action) => {
    var unitIdInLocalStorage = parseInt(localStorage.getItem('unitIdLocalStorage'));
    var typeInLocalStorage = localStorage.getItem('Types');
    console.log("TCL: lessonReducer -> unitIdInLocalStorage", unitIdInLocalStorage);
    var listLessonFormUnit = null;
    switch (action.type) {
        case Types.GETLESSONFROMUNIT:
            listLessonFormUnit = initialState.filter(x => x.UnitId === action.id);
            console.log("TCL: lessonReducer -> listLessonFormUnit", listLessonFormUnit)
            var unitId = action.id
            return {
                listLessonFormUnit,
                unitId
            }
        case Types.GETQUESTIONINACTIVITIES:
            return action.activityId
        case Types.GETACTIVITIESINLESSON:
            const listActivitiesFromLesson = initialState.find(x => x.lessonId === action.id && x.unitId === action.unitId);
            return {
                listActivitiesFromLesson,
                lessonId : action.id
            }
        default:
            listLessonFormUnit = initialState.filter(x => x.UnitId === unitIdInLocalStorage);
            return {
                listLessonFormUnit,
                unitId: unitIdInLocalStorage
            };
    }
}
export default lessonReducer;