export const LessonInUnit =
   [
      {
         "UnitId": 1,
         "Id": 1,
         "LessonName": "Lesson 1",
         "urlImage": "https://st.quantrimang.com/photos/image/2019/06/27/demo-la-gi-2.jpg"
      },
      {
         "UnitId": 1,
         "Id": 2,
         "LessonName": "Lesson 2",
         "urlImage": "https://st.quantrimang.com/photos/image/2019/06/27/demo-la-gi-2.jpg"
      },
      {
         "UnitId": 1,
         "Id": 3,
         "LessonName": "Lesson 3",
         "urlImage": "https://st.quantrimang.com/photos/image/2019/06/27/demo-la-gi-2.jpg",
         "UrlImage": ""
      },
      {
         "UnitId": 1,
         "Id": 4,
         "LessonName": "Lesson 4",
         "urlImage": "https://st.quantrimang.com/photos/image/2019/06/27/demo-la-gi-2.jpg",
         "UrlImage": ""
      },
      {
         "UnitId": 1,
         "Id": 5,
         "LessonName": "Lesson 5",
         "urlImage": "https://st.quantrimang.com/photos/image/2019/06/27/demo-la-gi-2.jpg",
         "UrlImage": ""
      }
      
   ]

