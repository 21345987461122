import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Row, Col, Popover, Button, Card } from "antd";
import renderHTML from 'react-render-html';


class ListenLookAndSay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            play: false
        }
    }
    togglePlay = () => {
        this.setState(
            { play: !this.state.play }
            , () => {
                this.state.play ? this.green.play() : this.green.pause();
            });
        setTimeout(() => {
            this.setState({
                play: false
            });
        }, 2000);
    }
    render() {
        var { ImageAndAudio } = this.props
        console.log(this.props.ImageAndAudio)
        return (
            <React.Fragment>
                <Col md={{span: 6 }} lg={{span: 6 }}
                            xs={{span: 6 }} sm={{span: 6 }} style={{paddingTop:30 , paddingLeft :7}}>
                    <img alt="example" style={{ width: '75%' }} src={require(`./../../assets/ImagesBigEngLish/${ImageAndAudio.ImageAudio[0].UrlImage}`)} />
                    <div
                        style=
                        {{
                            width: '85%',
                            borderBottomRightRadius: '35px',
                            position: 'relative',
                            border: '1px solid',
                            height: 50,
                            textAlign: 'center',
                            paddingTop: '3%',
                        }}
                    >
                        <p style={{ fontSize: '15px', color: 'black', marginTop: -3 }}>
                            <b>
                                {
                                    renderHTML(ImageAndAudio.ImageAudio[0].Name)
                                }
                            </b>
                        </p>
                        {/* <h3>
                            {renderHTML(ImageAndAudio.ImageAudio[0].Name)}
                        </h3> */}
                        <div style=
                            {{
                                position: "absolute",
                                top: '3%',
                                left: '-23px',
                                // width: '50px',
                                // height: '50px'
                            }}>
                            <Button style={{
                                border: '1px solid',
                                borderRadius: '50%',
                                width: '40px',
                                height: '40px',
                                display: 'inline-block',
                                lineHeight: '31px',
                                paddingLeft: '13px',
                                // float: 'left'
                            }}
                                type="primary"
                                shape="round"
                                icon={this.state.play === true ? 'pause' : 'caret-right'}
                                onClick={this.togglePlay} />
                            <div className="stage">
                                <audio ref={(green) => { this.green = green; }}>
                                    <source src={require(`./../../assets/AudiosBigEngLish/${ImageAndAudio.ImageAudio[0].UrlAudio}`)} type="audio/mpeg" >
                                    </source>
                                </audio>
                            </div>
                        </div>
                    </div>

                </Col>
            </React.Fragment>
        );
    }
}
export default ListenLookAndSay;
