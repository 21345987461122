export const questions =
   [
      //---------------Lesson 1
      {
         "unitId": 1,
         "lessonId": 1, "Id": 1,
         "Name": "Warm-up",
         "QuestionType": "ShowImageAndQuestion",
         "Questions": [
            {
               "Id": 1,
               "QuestionType": "",
               "QuestionText": "Do you help me at home?<br/> What do you do?",
               "QuestionAnswer": [
                  {
                     "Id": 39,
                     "QuestionId": 1,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 1,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-02.jpg",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 2,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-04.jpg",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 3,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-06.jpg",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 4,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-08.jpg",
                     "SortOrder": 3,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 5,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-10.jpg",
                     "SortOrder": 4,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 6,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-12.jpg",
                     "SortOrder": 5,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 7,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-14.jpg",
                     "SortOrder": 6,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 8,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-16.jpg",
                     "SortOrder": 7,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 2,
         "Name": "Lesson Objective 1",
         "QuestionType": "ShowImageAndQuestion",
         "Questions": [
            {
               "Id": 33,
               "QuestionType": "",
               "QuestionText": "Today I will learn to…",
               "QuestionAnswer": [
                  {
                     "Id": 38,
                     "QuestionId": 33,
                     "TextAnswer": "name chores.",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 41,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-02.jpg",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 42,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-04.jpg",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 43,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-06.jpg",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 44,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-08.jpg",
                     "SortOrder": 3,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 45,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-10.jpg",
                     "SortOrder": 4,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 46,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-12.jpg",
                     "SortOrder": 5,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 47,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-14.jpg",
                     "SortOrder": 6,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 48,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-16.jpg",
                     "SortOrder": 7,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 3,
         "Name": "Song Time",
         "QuestionType": "ListenAndSing",
         "Questions": [
            {
               "Id": 10,
               "QuestionType": "Listen and sing.",
               "QuestionText": "Listen and sing.",
               "QuestionAnswer": [
                  {
                     "Id": 40,
                     "QuestionId": 10,
                     "TextAnswer": "Listen And Sing",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 17,
                     "Name": "",
                     "Type": "Audio",
                     "UrlImage": "SongTime.png",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track51.mp3"
                  }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 4,
         "Name": "Presentation",
         "QuestionType": "ListenLookAndSay",
         "Questions": [
            {
               "Id": 11,
               "QuestionType": "Click image and show text",
               "QuestionText": "Listen, look and say",
               "QuestionAnswer": [
                  {
                     "Id": 41,
                     "QuestionId": 11,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 18,
                     "Name": "make my bed",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-02.jpg",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net.mp3"
                  }
               ]
            },
            {
               "Id": 12,
               "QuestionType": "Click image and show text",
               "QuestionText": "Walk the dog",
               "QuestionAnswer": [
                  {
                     "Id": 42,
                     "QuestionId": 12,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 19,
                     "Name": "walk the dog",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-04.jpg",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-1.mp3"
                  }
               ]
            },
            {
               "Id": 13,
               "QuestionType": "Click image and show text",
               "QuestionText": "pratice the piano",
               "QuestionAnswer": [
                  {
                     "Id": 43,
                     "QuestionId": 13,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 20,
                     "Name": "practice the <br/>piano",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-06.jpg",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-2.mp3"
                  }
               ]
            },
            {
               "Id": 14,
               "QuestionType": "Click image and show text",
               "QuestionText": "take out the trash",
               "QuestionAnswer": [
                  {
                     "Id": 44,
                     "QuestionId": 14,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 21,
                     "Name": "take out the <br/> trash",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-08.jpg",
                     "SortOrder": 3,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-8.mp3"
                  }
               ]
            },
            {
               "Id": 15,
               "QuestionType": "Click image and show text",
               "QuestionText": "do the dishes",
               "QuestionAnswer": [
                  {
                     "Id": 45,
                     "QuestionId": 15,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 22,
                     "Name": "do the dishes",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-10.jpg",
                     "SortOrder": 4,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net4.mp3"
                  }
               ]
            },
            {
               "Id": 16,
               "QuestionType": "Click image and show text",
               "QuestionText": "clean my room",
               "QuestionAnswer": [
                  {
                     "Id": 46,
                     "QuestionId": 16,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 23,
                     "Name": "clean my room",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-12.jpg",
                     "SortOrder": 5,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-5.mp3"
                  }
               ]
            },
            {
               "Id": 17,
               "QuestionType": "Click image and show text",
               "QuestionText": "study for a test",
               "QuestionAnswer": [
                  {
                     "Id": 47,
                     "QuestionId": 17,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 24,
                     "Name": "study for a test",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-14.jpg",
                     "SortOrder": 6,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-6.mp3"
                  }
               ]
            },
            {
               "Id": 18,
               "QuestionType": "Click image and show text",
               "QuestionText": "feed the fish",
               "QuestionAnswer": [
                  {
                     "Id": 48,
                     "QuestionId": 18,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 25,
                     "Name": "feed the fish",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-16.jpg",
                     "SortOrder": 7,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-7.mp3"
                  }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 5,
         "Name": "Practice 1",
         "QuestionType": "DragDrop",
         "Questions": [
            {
               "Id": 2,
               "QuestionType": "Read and choose",
               "QuestionText": "clean my …",
               "QuestionAnswer": [
                  {
                     "Id": 1,
                     "QuestionId": 2,
                     "TextAnswer": "room",
                     "isCorrect": true
                  },
                  {
                     "Id": 2,
                     "QuestionId": 2,
                     "TextAnswer": "dishes",
                     "isCorrect": false
                  },
                  {
                     "Id": 3,
                     "QuestionId": 2,
                     "TextAnswer": "bed",
                     "isCorrect": false
                  },
                  {
                     "Id": 4,
                     "QuestionId": 2,
                     "TextAnswer": "dog",
                     "isCorrect": false
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 9,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-02.jpg",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 3,
               "QuestionType": "Read and choose",
               "QuestionText": "do the …",
               "QuestionAnswer": [
                  {
                     "Id": 5,
                     "QuestionId": 3,
                     "TextAnswer": "room",
                     "isCorrect": false
                  },
                  {
                     "Id": 6,
                     "QuestionId": 3,
                     "TextAnswer": "dishes",
                     "isCorrect": true
                  },
                  {
                     "Id": 7,
                     "QuestionId": 3,
                     "TextAnswer": "bed",
                     "isCorrect": false
                  },
                  {
                     "Id": 8,
                     "QuestionId": 3,
                     "TextAnswer": "dog",
                     "isCorrect": false
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 10,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-04.jpg",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 4,
               "QuestionType": "Read and choose",
               "QuestionText": "make my…",
               "QuestionAnswer": [
                  {
                     "Id": 9,
                     "QuestionId": 4,
                     "TextAnswer": "room",
                     "isCorrect": false
                  },
                  {
                     "Id": 10,
                     "QuestionId": 4,
                     "TextAnswer": "dishes",
                     "isCorrect": false
                  },
                  {
                     "Id": 11,
                     "QuestionId": 4,
                     "TextAnswer": "bed",
                     "isCorrect": true
                  },
                  {
                     "Id": 12,
                     "QuestionId": 4,
                     "TextAnswer": "dog",
                     "isCorrect": false
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 11,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-06.jpg",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 5,
               "QuestionType": "Read and choose",
               "QuestionText": "walk the…",
               "QuestionAnswer": [
                  {
                     "Id": 13,
                     "QuestionId": 5,
                     "TextAnswer": "room",
                     "isCorrect": false
                  },
                  {
                     "Id": 14,
                     "QuestionId": 5,
                     "TextAnswer": "dishes",
                     "isCorrect": false
                  },
                  {
                     "Id": 15,
                     "QuestionId": 5,
                     "TextAnswer": "bed",
                     "isCorrect": false
                  },
                  {
                     "Id": 16,
                     "QuestionId": 5,
                     "TextAnswer": "dog",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 12,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-08.jpg",
                     "SortOrder": 3,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 6,
               "QuestionType": "Read and choose",
               "QuestionText": "practice the",
               "QuestionAnswer": [
                  {
                     "Id": 17,
                     "QuestionId": 6,
                     "TextAnswer": "piano",
                     "isCorrect": true
                  },
                  {
                     "Id": 18,
                     "QuestionId": 6,
                     "TextAnswer": "test",
                     "isCorrect": false
                  },
                  {
                     "Id": 19,
                     "QuestionId": 6,
                     "TextAnswer": "trash",
                     "isCorrect": false
                  },
                  {
                     "Id": 20,
                     "QuestionId": 6,
                     "TextAnswer": "fish",
                     "isCorrect": false
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 13,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-10.jpg",
                     "SortOrder": 4,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 7,
               "QuestionType": "Read and choose",
               "QuestionText": "study for a ",
               "QuestionAnswer": [
                  {
                     "Id": 21,
                     "QuestionId": 7,
                     "TextAnswer": "piano",
                     "isCorrect": false
                  },
                  {
                     "Id": 22,
                     "QuestionId": 7,
                     "TextAnswer": "test",
                     "isCorrect": true
                  },
                  {
                     "Id": 23,
                     "QuestionId": 7,
                     "TextAnswer": "trash",
                     "isCorrect": false
                  },
                  {
                     "Id": 24,
                     "QuestionId": 7,
                     "TextAnswer": "fish",
                     "isCorrect": false
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 14,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-12.jpg",
                     "SortOrder": 5,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 8,
               "QuestionType": "Read and choose",
               "QuestionText": "take out the",
               "QuestionAnswer": [
                  {
                     "Id": 25,
                     "QuestionId": 8,
                     "TextAnswer": "piano",
                     "isCorrect": false
                  },
                  {
                     "Id": 26,
                     "QuestionId": 8,
                     "TextAnswer": "test",
                     "isCorrect": false
                  },
                  {
                     "Id": 27,
                     "QuestionId": 8,
                     "TextAnswer": "trash",
                     "isCorrect": true
                  },
                  {
                     "Id": 28,
                     "QuestionId": 8,
                     "TextAnswer": "fish",
                     "isCorrect": false
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 15,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-14.jpg",
                     "SortOrder": 6,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 9,
               "QuestionType": "Read and choose",
               "QuestionText": "feed the",
               "QuestionAnswer": [
                  {
                     "Id": 29,
                     "QuestionId": 9,
                     "TextAnswer": "piano",
                     "isCorrect": false
                  },
                  {
                     "Id": 30,
                     "QuestionId": 9,
                     "TextAnswer": "test",
                     "isCorrect": false
                  },
                  {
                     "Id": 31,
                     "QuestionId": 9,
                     "TextAnswer": "trash",
                     "isCorrect": false
                  },
                  {
                     "Id": 32,
                     "QuestionId": 9,
                     "TextAnswer": "fish",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 16,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-16.jpg",
                     "SortOrder": 7,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 6,
         "Name": "Game",
         "QuestionType": "Click image and show text",
         "Questions": [
            {
               "Id": 11,
               "QuestionType": "Click image and show text",
               "QuestionText": "Play the acting game. What do I do at home?",
               "QuestionAnswer": [
                  {
                     "Id": 41,
                     "QuestionId": 11,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 18,
                     "Name": "make my bed",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-02.jpg",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 12,
               "QuestionType": "Click image and show text",
               "QuestionText": "Walk the dog",
               "QuestionAnswer": [
                  {
                     "Id": 42,
                     "QuestionId": 12,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 19,
                     "Name": "walk the dog",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-04.jpg",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 13,
               "QuestionType": "Click image and show text",
               "QuestionText": "pratice the piano",
               "QuestionAnswer": [
                  {
                     "Id": 43,
                     "QuestionId": 13,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 20,
                     "Name": "practice the piano",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-06.jpg",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 14,
               "QuestionType": "Click image and show text",
               "QuestionText": "take out the trash",
               "QuestionAnswer": [
                  {
                     "Id": 44,
                     "QuestionId": 14,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 21,
                     "Name": "take out the trash",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-08.jpg",
                     "SortOrder": 3,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 15,
               "QuestionType": "Click image and show text",
               "QuestionText": "do the dishes",
               "QuestionAnswer": [
                  {
                     "Id": 45,
                     "QuestionId": 15,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 22,
                     "Name": "do the dishes",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-10.jpg",
                     "SortOrder": 4,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 16,
               "QuestionType": "Click image and show text",
               "QuestionText": "clean my room",
               "QuestionAnswer": [
                  {
                     "Id": 46,
                     "QuestionId": 16,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 23,
                     "Name": "clean my room",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-12.jpg",
                     "SortOrder": 5,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 17,
               "QuestionType": "Click image and show text",
               "QuestionText": "study for a test",
               "QuestionAnswer": [
                  {
                     "Id": 47,
                     "QuestionId": 17,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 24,
                     "Name": "study for a test",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-14.jpg",
                     "SortOrder": 6,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 18,
               "QuestionType": "Click image and show text",
               "QuestionText": "feed the fish",
               "QuestionAnswer": [
                  {
                     "Id": 48,
                     "QuestionId": 18,
                     "TextAnswer": "feed the fish",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 25,
                     "Name": "feed the fish",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-16.jpg",
                     "SortOrder": 7,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "QuestionType": "Listen and sing for mutiple audio",
         "Id": 7,
         "Name": "Listen and sing",
         "Questions": [
            {
               "Id": 19,
               "QuestionType": "Listen and sing.",
               "QuestionText": "<b>Listen and sing.</b>",
               "QuestionAnswer": [
                  {
                     "Id": 49,
                     "QuestionId": 19,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 26,
                     "Name": "My name’s Matt,<br/>And my name’s Mike.</br>We want to talk to you.<br/><br/>I do my chores,And I do, too.<br/>But we are not alike.",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": [{Audio:"CD1_Track49-mp3cut.net.mp3"}]
                  },
                  {
                     "Id": 27,
                     "Name": "Mike and Matt, Matt and Mike.<br/>These two twins are not alike.",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": [{Audio:"CD1_Track49-mp3cut.net-1.mp3"}]
                  },
                  {
                     "Id": 28,
                     "Name": "I’m Matt, I always clean my room.<br/>I do my chores each day.<br/>I sometimes do the dishes,<br/>And then we go and play.",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": [{Audio:"CD1_Track49-mp3cut.net2.mp3"}]
                  },
                  {
                     "Id": 200,
                     "Name": "Mike and Matt, Matt and Mike.<br/>These two twins are not alike.",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": [{Audio:"CD1_Track49-mp3cut.net-1.mp3"}]
                  },
                  {
                     "Id": 201,
                     "Name": "I’m Mike, I always make my bed.<br/>I do my chores each day.</br>I sometimes walk the dog,</br>And then we go and play.",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": [{Audio:"CD1_Track49-mp3cut.net-1.mp3"}]
                  },
                  {
                     "Id": 202,
                     "Name": "Mike and Matt, Matt and Mike.These two twins are not alike.",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": [{Audio:"CD1_Track49-mp3cut.net-1.mp3"}]
                  }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 8,
         "Name": "Speaking",
         "QuestionType" : "Show1ImageAndQuestion",
         "Questions": [
            {
               "Id": 20,
               "QuestionType": "",
               "QuestionText": "Use the chart to ask and answer questions about Matt.",
               "QuestionAnswer": [
                  {
                     "Id": 50,
                     "QuestionId": 20,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 29,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_Speaking.PNG",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  // {
                  //    "Id": 58,
                  //    "Name": "",
                  //    "Type": "image",
                  //    "UrlImage": "Annotation2019-11-25141300.png",
                  //    "SortOrder": 1,
                  //    "Tags": "\"",
                  //    "UrlAudio": ""
                  // }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 9,
         "Name": "Practice 2",
         "QuestionType" : "Show1ImageAndQuestion",
         "Questions": [
            {
               "Id": 21,
               "QuestionType": "",
               "QuestionText": "",
               "QuestionAnswer": [
                  {
                     "Id": 51,
                     "QuestionId": 21,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 30,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_Practice2.PNG",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 10,
         "Name": "Think Big",
         "QuestionType" : "Show1ImageAndQuestion",
         "Questions": [
            {
               "Id": 10000,
               "QuestionType": "",
               "QuestionText": "",
               "QuestionAnswer": [
                  {
                     "Id": 400,
                     "QuestionId": 1,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 99,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_ThinkBig1.PNG",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
               ]
            },
           
         ]
         
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 11,
         "Name": "Lesson Objective 2",
         "QuestionType": "NextQuestionAndImage",
         "Questions": [
            {
               "Id": 22,
               "QuestionType": "",
               "QuestionText": "<b>What have I learned?</b>",
               "QuestionAnswer": [
                  {
                     "Id": 33,
                     "QuestionId": 22,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 59,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "", //Annotation2019-11-25142017.png
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 60,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "Annotation2019-11-25-142017.png",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 61,
                     "Name": "I have learned to name chores.",
                     "Type": "image",
                     "UrlImage": "Annotation2019-11-25-142017.png",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 31,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "", //Annotation2019-11-25142017.png
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 57,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "Annotation2019-11-25142141.png",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 58,
                     "Name": "I have learned to ask and answer about chores.",
                     "Type": "image",
                     "UrlImage": "Annotation2019-11-25142141.png",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
               ]
            }
         ]
      },

      {
         "unitId": 1,
         "lessonId": 1,
         "Id": 12,
         "Name": "Homework",
         "Questions": [
            {
               "Id": 23,
               "QuestionType": "Listen audio and match",
               "QuestionText": "",
               "ImageAudio": [
                  {
                     "Id": 32,
                     "Name": "",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-1.mp3"
                  }
               ]
            },
            {
               "Id": 24,
               "QuestionType": "Listen audio and match",
               "QuestionText": "",
               "ImageAudio": [
                  {
                     "Id": 33,
                     "Name": "",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-2.mp3"
                  }
               ]
            },
            {
               "Id": 25,
               "QuestionType": "Listen audio and match",
               "QuestionText": "",
               "ImageAudio": [
                  {
                     "Id": 34,
                     "Name": "",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-5.mp3"
                  }
               ]
            },
            {
               "Id": 26,
               "QuestionType": "Listen audio and match",
               "QuestionText": "",
               "ImageAudio": [
                  {
                     "Id": 35,
                     "Name": "",
                     "Type": "Audio",
                     "UrlImage": "",
                     "SortOrder": 3,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-3.mp3"
                  }
               ]
            },
            {
               "Id": 27,
               "QuestionType": "Match",
               "QuestionText": "practice",
               "QuestionAnswer": [
                  {
                     "Id": 34,
                     "QuestionId": 27,
                     "TextAnswer": "the piano",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 36,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-02.jpg",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 28,
               "QuestionType": "Match",
               "QuestionText": "study",
               "QuestionAnswer": [
                  {
                     "Id": 35,
                     "QuestionId": 28,
                     "TextAnswer": "for a test",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 37,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-04.jpg",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 29,
               "QuestionType": "Match",
               "QuestionText": "walk",
               "QuestionAnswer": [
                  {
                     "Id": 36,
                     "QuestionId": 29,
                     "TextAnswer": "my room",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 38,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-06.jpg",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 30,
               "QuestionType": "Match",
               "QuestionText": "clean",
               "QuestionAnswer": [
                  {
                     "Id": 37,
                     "QuestionId": 30,
                     "TextAnswer": "the dog",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 39,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-08.jpg",
                     "SortOrder": 3,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 31,
               "QuestionType": "Listen song and fill input",
               "QuestionText": "I’m Matt, I always….I do my chores each day",
               "ImageAudio": [
                  {
                     "Id": 40,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "Annotation2019-11-25142017.png",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  }
               ]
            },
            {
               "Id": 32,
               "QuestionType": "Listen song and fill input",
               "QuestionText": "I sometimes…,And then we go and play."
            }
         ]
      },
       //---------------Lesson 2
      {
         "unitId": 1,
         "lessonId": 2, "Id": 1,
         "Name": "Warm-up",
         "QuestionType": "Show1ImageAndQuestion",
         "Questions": [
            {
               "Id": 1,
               "QuestionType": "",
               "QuestionText": "<b>What time do you wake up?</b>",
               "QuestionAnswer": [
                  {
                     "Id": 39,
                     "QuestionId": 1,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 1,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "Unit1-Lesson2-WarmUp1.PNG",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
               ]
            }
         ]
      },
      {
         "unitId": 1,
         "lessonId": 2, "Id": 2,
         "Name": "Lesson Objective 1",
         "QuestionType": "Show1ImageAndQuestion",
         "Questions": [
            {
               "Id": 1,
               "QuestionType": "",
               "QuestionText": "<b>Today I will…</b>",
               "QuestionAnswer": [
                  {
                     "Id": 40,
                     "QuestionId": 1,
                     "TextAnswer": "read a story about chores.",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 1,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "Unit1-Lesson2-LessonObjective-1.PNG",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
               ]
            }
         ]
      },
      {
         "unitId": 1,
         "lessonId": 2,
         "Id": 15,
         "Name": "Revision",
         "QuestionType": "ListenLookAndSay",
         "Questions": [
            {
               "Id": 19,
               "QuestionType": "Click image and show text",
               "QuestionText": "Listen, look and say",
               "QuestionAnswer": [
                  {
                     "Id": 41,
                     "QuestionId": 11,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 18,
                     "Name": "make my bed",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-02.jpg",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net.mp3"
                  }
               ]
            },
            {
               "Id": 20,
               "QuestionType": "Click image and show text",
               "QuestionText": "Walk the dog",
               "QuestionAnswer": [
                  {
                     "Id": 42,
                     "QuestionId": 12,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 19,
                     "Name": "walk the dog",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-04.jpg",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-1.mp3"
                  }
               ]
            },
            {
               "Id": 21,
               "QuestionType": "Click image and show text",
               "QuestionText": "pratice the piano",
               "QuestionAnswer": [
                  {
                     "Id": 43,
                     "QuestionId": 13,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 20,
                     "Name": "practice the <br/>piano",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-06.jpg",
                     "SortOrder": 2,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-2.mp3"
                  }
               ]
            },
            {
               "Id": 22,
               "QuestionType": "Click image and show text",
               "QuestionText": "take out the trash",
               "QuestionAnswer": [
                  {
                     "Id": 44,
                     "QuestionId": 14,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 21,
                     "Name": "take out the <br/> trash",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-08.jpg",
                     "SortOrder": 3,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-8.mp3"
                  }
               ]
            },
            {
               "Id": 23,
               "QuestionType": "Click image and show text",
               "QuestionText": "do the dishes",
               "QuestionAnswer": [
                  {
                     "Id": 45,
                     "QuestionId": 15,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 22,
                     "Name": "do the dishes",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-10.jpg",
                     "SortOrder": 4,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net4.mp3"
                  }
               ]
            },
            {
               "Id": 24,
               "QuestionType": "Click image and show text",
               "QuestionText": "clean my room",
               "QuestionAnswer": [
                  {
                     "Id": 46,
                     "QuestionId": 16,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 23,
                     "Name": "clean my room",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-12.jpg",
                     "SortOrder": 5,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-5.mp3"
                  }
               ]
            },
            {
               "Id": 25,
               "QuestionType": "Click image and show text",
               "QuestionText": "study for a test",
               "QuestionAnswer": [
                  {
                     "Id": 47,
                     "QuestionId": 17,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 24,
                     "Name": "study for a test",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-14.jpg",
                     "SortOrder": 6,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-6.mp3"
                  }
               ]
            },
            {
               "Id": 26,
               "QuestionType": "Click image and show text",
               "QuestionText": "feed the fish",
               "QuestionAnswer": [
                  {
                     "Id": 48,
                     "QuestionId": 18,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 25,
                     "Name": "feed the fish",
                     "Type": "image",
                     "UrlImage": "BIGAME2E3_OW_FCU3-16.jpg",
                     "SortOrder": 7,
                     "Tags": "\"",
                     "UrlAudio": "CD1_Track47-mp3cut.net-7.mp3"
                  }
               ]
            }
         ]
      },
      {
         "unitId": 1,
         "lessonId": 2,
         "Id": 16,
         "Name": "Pre-reading",
         "QuestionType": "NextQuestionAndImage",
         "Questions": [
            {
               "Id": 22,
               "QuestionType": "",
               "QuestionText": "<b>What have I learned?</b>",
               "QuestionAnswer": [
                  {
                     "Id": 33,
                     "QuestionId": 22,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 59,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "Unit1-Lesson2-Pre-reading1.PNG", //Annotation2019-11-25142017.png
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 60,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "Unit1-Lesson2-Pre-reading2.PNG",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
                  {
                     "Id": 61,
                     "Name": "",
                     "Type": "image",
                     "UrlImage": "Unit1-Lesson2-Pre-reading3.PNG",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": ""
                  },
               ]
            }
         ]
      },
      {
         "unitId": 1,
         "lessonId": 2,
         "QuestionType": "Listen and sing for mutiple audio",
         "Id": 17,
         "Name": "Reading",
         "Questions": [
            {
               "Id": 19,
               "QuestionType": "Listen and sing.",
               "QuestionText": "<b>Listen and read. What time does Amy have to leave for school?</b>",
               "QuestionAnswer": [
                  {
                     "Id": 49,
                     "QuestionId": 19,
                     "TextAnswer": "",
                     "isCorrect": true
                  }
               ],
               "ImageAudio": [
                  {
                     "Id": 26,
                     "Name": "",
                     "Type": "Audio",
                     "UrlImage": "Unit1-Lesson2-Pre-reading1.PNG",
                     "SortOrder": 0,
                     "Tags": "\"",
                     "UrlAudio": [
                        {Audio: "CD1_Track49-mp3cut.net.mp3"},
                        {Audio: "CD1_Track49-mp3cut.net-1.mp3"},
                     ],
                  },
                  {
                     "Id": 27,
                     "Name": "",
                     "Type": "Audio",
                     "UrlImage": "Unit1-Lesson2-Pre-reading2.PNG",
                     "SortOrder": 1,
                     "Tags": "\"",
                     "UrlAudio": [
                        {Audio: "CD1_Track49-mp3cut.net.mp3"},
                        {Audio: "CD1_Track49-mp3cut.net-1.mp3"},
                     ],
                  },
               ]
            }
         ]
      },
   ]
