import React, { Component } from 'react';
import { Layout, Card, Row, Col, Button } from 'antd';
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/index';
const { Meta } = Card;
class UnitScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            a: 500
        };
    }
    onSaveId = (id) => {
        this.props.onSaveUnitId(id)
    }
    onChangeButton = () => {
        this.setState({
            onFause: !this.state.onFause
        })
        console.log(this.state.onFause)
    }
    render() {
        var { units } = this.props;
        console.log(units)
        return (
            <div>
                <Row>
                    <Row gutter={16}>
                        {units.length > 0 && units.map((unit, index) => {
                            return (
                                <Col key={index} md={{ span: 8 }} lg={{ span: 6 }} xs={{ span: 6 }} sm={{ span: 12 }}>

                                    <Link to={`/${unit.id}/Lesson`}>
                                        <div >
                                            <Card
                                                // hoverable
                                                style={{
                                                    // width: 240 ,
                                                    boxShadow: '0px 1px 0px #888888'
                                                }}
                                                onClick={() => this.onSaveId(unit.id)}
                                                cover={<img alt="example" src={require(`../assets/ImagesBigEngLish/${unit.urlImage}`)} />}
                                            >
                                                <p style={{ fontSize: 18, textAlign: 'center', color: 'black' }}><b>{unit.UnitName}</b></p>
                                            </Card>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })}
                    </Row>
                </Row>
            </div >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        units: state.UnitReducer
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        onSaveUnitId: (id) => {
                dispatch(actions.SaveUnitId(id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitScreen);